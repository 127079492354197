import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/docs/src/components/layouts/documentation.js";
import SEO from '../../components/seo';
import { Navbar, NavLink } from 'godspeed';
import logo from '../../assets/logo-white.png';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <SEO title="Navbar" description="hello description" mdxType="SEO" />
    <h1></h1>
    <h1>{`Navbar`}</h1>
    <h2>{`Navbar allow the user to execute events, actions or to navigate.`}</h2>
    <h3>{`Default Behavior`}</h3>
    <ul>
      <li parentName="ul">{`Position is 'fixed'`}</li>
      <li parentName="ul">{`Top of the view port at '0px'`}</li>
      <li parentName="ul">{`Width '100%' of the parent`}</li>
      <li parentName="ul">{`Note: Should be lifted to parent with width of '100vw'`}</li>
    </ul>
    <br />
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`import { Navbar } from 'godspeed'
`}</code></pre>
    <br />
    <h1>{`Default Navbars`}</h1>
    <h2>{`These come out of the box, dark with white text.`}</h2>
    <h2>{`The "title" prop is required on all navbar instances.`}</h2>
    <h2>{`This component expects children.`}</h2>
    <h3>{`Navbar Items Behavior`}</h3>
    <ul>
      <li parentName="ul">{`Navbar is a flexbox`}</li>
      <li parentName="ul">{`Items are 'space-between'`}</li>
      <li parentName="ul">{`Title is at 'flex-start'`}</li>
      <li parentName="ul">{`Children are at pushed to end by 'space-between'`}</li>
    </ul>
    <p>{`You can pass the "bg" prop to change the background of the navbar.`}</p>
    <p>{`You can pass the "color" prop to change the font color of the navbar.`}</p>
    <main style={{
      width: '100%',
      marginTop: 20,
      marginBottom: 20,
      border: '1px solid black',
      borderRadius: 5,
      padding: '20px 0'
    }}>
  <Navbar title="Navbar" style={{
        position: 'unset'
      }} mdxType="Navbar">
    <a href="#" style={{
          marginRight: 10
        }}>Link</a>
    <a href="#" style={{
          marginRight: 10
        }}>Link</a>
    <a href="#">Link</a>
  </Navbar>
  <br />
  <Navbar title="Navbar" style={{
        position: 'unset'
      }} bg="rgb(200, 200, 200)" color="steelblue" mdxType="Navbar">
    <a href="#" style={{
          marginRight: 10
        }}>Link</a>
    <a href="#" style={{
          marginRight: 10
        }}>Link</a>
    <a href="#">Link</a>
  </Navbar>
    </main>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`<Navbar title="Navbar">
  <a href="#">Item</a>
  <a href="#">Item</a>
  <a href="#">Link</a>
</Navbar>
<br />
<Navbar title="Navbar" bg="rgb(200, 200, 200)" color="steelblue">
  <a href="#">Item</a>
  <a href="#">Item</a>
  <a href="#">Link</a>
</Navbar>
`}</code></pre>
    <h1></h1>
    <h1>{`NavLinks`}</h1>
    <h2>{`A Navbar specific component to act as a link or button.`}</h2>
    <h2>{`This component expects children.`}</h2>
    <br />
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`import { NavLink } from 'godspeed'
`}</code></pre>
    <br />
    <h3>{`NavLink Behavior`}</h3>
    <ul>
      <li parentName="ul">{`Heigth of parent '100%'`}</li>
      <li parentName="ul">{`Background color 'transparent'`}</li>
      <li parentName="ul">{`Hover color is Navbar color lightened`}</li>
      <li parentName="ul">{`Items, except last item, have margin right '10px'`}</li>
      <li parentName="ul">{`Note: Use 'to' prop to act as link, 'onClick' prop to act as action button`}</li>
      <li parentName="ul">{`The font color is 'inherit' from the parent`}</li>
    </ul>
    <h2>{`You can pass the "hover" prop to change the hover color of the navLink.`}</h2>
    <main style={{
      width: '100%',
      marginTop: 20,
      marginBottom: 20,
      border: '1px solid black',
      borderRadius: 5,
      padding: '20px 0'
    }}>
  <Navbar title="Navbar" style={{
        position: 'unset'
      }} mdxType="Navbar">
    <NavLink to="#" mdxType="NavLink">Link</NavLink>
    <NavLink onClick={() => window.location.href = '#'} mdxType="NavLink">Button</NavLink>
  </Navbar>
  <br />
  <Navbar title="Navbar" style={{
        position: 'unset'
      }} mdxType="Navbar">
    <NavLink to="#" hover="steelblue" mdxType="NavLink">Custom Hover</NavLink>
    <NavLink to="#" mdxType="NavLink">Default Hover</NavLink>
  </Navbar>
    </main>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`<Navbar title="Navbar">
  <NavLink to="#">Link</NavLink>
  <NavLink onClick={() => console.log('Godspeed')}>Button</NavLink>
</Navbar>
<Navbar title="Navbar">
  <NavLink to="#" hover="steelblue">Custom Hover</NavLink>
  <NavLink to="#">Default Hover</NavLink>
</Navbar>
`}</code></pre>
    <h1></h1>
    <h1>{`Customized Navbars`}</h1>
    <main style={{
      width: '100%',
      marginTop: 20,
      marginBottom: 20,
      border: '1px solid black',
      borderRadius: 5,
      padding: '20px 0'
    }}>
  <Navbar title="Navbar" style={{
        position: 'unset'
      }} shadow mdxType="Navbar">
    <NavLink to="#" mdxType="NavLink">Link</NavLink>
    <NavLink to="#" mdxType="NavLink">Link</NavLink>
    <NavLink to="#" mdxType="NavLink">Link</NavLink>
  </Navbar>
  <br />
  <Navbar title="Navbar" style={{
        position: 'unset'
      }} height="100px" mdxType="Navbar">
    <NavLink to="#" mdxType="NavLink">Link</NavLink>
    <NavLink to="#" mdxType="NavLink">Link</NavLink>
    <NavLink to="#" mdxType="NavLink">Link</NavLink>
  </Navbar>
  <br />
  <Navbar title="Navbar" style={{
        position: 'unset'
      }} inv mdxType="Navbar">
    <NavLink to="#" mdxType="NavLink">Link</NavLink>
    <NavLink to="#" mdxType="NavLink">Link</NavLink>
    <NavLink to="#" mdxType="NavLink">Link</NavLink>
  </Navbar>
    </main>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`<Navbar title="Navbar" shadow>
  <NavLink to="#">Link</NavLink>
  <NavLink to="#">Link</NavLink>
  <NavLink to="#">Link</NavLink>
</Navbar>
<Navbar title="Navbar" height="55px">
  <NavLink to="#">Link</NavLink>
  <NavLink to="#">Link</NavLink>
  <NavLink to="#">Link</NavLink>
</Navbar>
<Navbar title="Navbar" inv>
  <NavLink to="#">Link</NavLink>
  <NavLink to="#">Link</NavLink>
  <NavLink to="#">Link</NavLink>
</Navbar>
`}</code></pre>
    <h1></h1>
    <h1>{`Navbar Logos`}</h1>
    <p>{`You can pass the "logo" prop with an image to add your logo to the center.`}</p>
    <main style={{
      width: '100%',
      marginTop: 20,
      marginBottom: 20,
      border: '1px solid black',
      borderRadius: 5,
      padding: '20px 0'
    }}>
  <Navbar title="Navbar" style={{
        position: 'unset'
      }} logo={logo} mdxType="Navbar">
    <NavLink to="#" mdxType="NavLink">Link</NavLink>
    <NavLink to="#" mdxType="NavLink">Link</NavLink>
    <NavLink to="#" mdxType="NavLink">Link</NavLink>
  </Navbar>
    </main>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`import logo from '../images/godspeed-logo.png'

<Navbar title="Navbar" logo={logo}>
  <NavLink to="#">Link</NavLink>
  <NavLink to="#">Link</NavLink>
  <NavLink to="#">Link</NavLink>
</Navbar>
`}</code></pre>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      